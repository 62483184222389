import React from "react"
import styled from "styled-components"

import Layout from "../../components/layout"
import Consts from "../../consts"

const ItemWrapper = styled.div`
    margin: 0 3em;
    padding-top: 1em;
`

const Paragraph = styled.p`
  text-align: justify;
  margin-block-start: 2em;
`

class SzkoleniaPage extends React.Component {
  render() {
    return (
      <Layout location={this.props.location.pathname}>
        <div style={{
          display: `flex`,
          flexDirection: `row`,
          justifyContent: `center`,
          flexWrap: `wrap`,
        }}>

          <ItemWrapper>
            <Paragraph>
              Szkolenia skierowane są do przedstawicieli: Urzędów Miast i Gmin, Prezydentów/Burmistrzów/Wójtów,
              przewodniczących KRPA, Pełnomocników ds. Profilaktyki i RPA, członków i kandydatów do KRPA. osób
              wydających
              zezwolenia na sprzedaż alkoholu, osób, które należą do interdyscyplinarnych zespołów ds. przeciwdziałania
              przemocy w rodzinie, kierowników OPS, pracowników socjalnych, asystentów rodziny, policjantów, strażników
              miejskich, przedstawicieli służby zdrowia, kuratorów, pracowników PCPR, przedstawicieli oświaty:
              dyrektorów,
              pedagogów/psychologów szkolnych/przedszkolnych, pracowników świetlic oraz innych specjalistów
              zainteresowanych
              tematyką szkolenia.
            </Paragraph>

            <Paragraph>
              <strong>
                Organizujemy szkolenia online ogólnopolskie oraz indywidualne dla danej Gminy, tematyka dostosowywana
                jest wedle zainteresowania
              </strong>
            </Paragraph>

            <Paragraph>
              Terminy szkoleń w 2024r. dostępne u koordynatora pod nr. tel.&nbsp;
              <a
                href={`tel:${Consts.DATA.PHONE.CELLPHONE}`}
              >{Consts.DATA.PHONE.CELLPHONE}</a>
              &nbsp;/&nbsp;
              <a
                href={`tel:${Consts.DATA.PHONE.LANDLINE}`}
              >{Consts.DATA.PHONE.LANDLINE}</a>
            </Paragraph>

            <Paragraph>
              Przykładowe tematy szkolenie online:

              <ul>
                <li>Rola i zadania KRPA</li>
                <li>Kontrola punktów sprzedaży napojów alkoholowych</li>
                <li>Wydawanie zezwoleń na sprzedaż napojów alkoholowych</li>
                <li>Zmiany w ustawie o przeciwdziałaniu przemocy</li>
                <li>Rola i zadania ZI oraz grup diagnostyczno-pomocowych</li>
                <li>Uzależnienia behawioralne</li>
                <li>Fonoholizm</li>
                <li>Piecza Zastępcza</li>
                <li>Dialog Motywujący</li>
                <li>FAS</li>
                <li>Wczesne wspomaganie rozwoju dziecka w przedszkolu</li>
                <li>inne</li>
              </ul>

            </Paragraph>
          </ItemWrapper>

        </div>
      </Layout>
    )
  }
}

export default SzkoleniaPage
